import request from './request'

//获取APP版本链接
const getVersionLink = (data) => {
    return request('app_mallapi/appversion/getAppLink', data, 'get')
}
//用户提交留言
const saveMessage = (data) => {
    return request('app_mallapi/guestbook/saveofficerwebsite', data, 'post')
}

export {
    getVersionLink,
    saveMessage
}
