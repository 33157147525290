import Axios from "axios"
//import { Loading } from 'element-ui';

const instance = Axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {}
});

const request = (url, data = {}, method = 'get') => {
    // const loading = Loading.service({
    //     lock: true,
    //     text: 'Loading',
    //     spinner: 'el-icon-loading',
    //     background: 'rgba(0, 0, 0, 0.7)'
    // });
    return new Promise((resolve, reject) => {
        const options = {
            url,
            method
        }
        if (method.toLowerCase() === 'get') {
            options.params = data
        } else {
            options.data = data
        }
        instance(options)
            .then(res => {
                if(res.data.code === 0 || res.data.code === 200){
                    resolve(res.data.data)
                }else{
                    reject(res.data.meg)
                }
                //loading.close();
            })
            .catch(error => {
                reject(error);
                //loading.close();
            })
    })
}

export default request
